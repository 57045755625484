<template>
	<div>
		<ClientNavbar class="is-hidden-touch" :navClass="navClass" :section="section" />
		<section class="has-background-blue is-hidden-touch" :style="paddingTop">
			<div class="columns is-mobile is-multiline">
				<div class="column is-5-desktop is-offset-1-desktop is-12-touch">
					<div v-if="activeTab == 'new_account'">
						<h3 class="has-text-white is-title-1-d-d">{{t.txt1}}</h3>
						<img :src="require('@/assets/imgs/Auth/1.png')" alt />
					</div>
					<div v-else>
						<h3 class="has-text-white is-title-1-d-d">{{t.txt2}}</h3>
						<img :src="require('@/assets/imgs/Auth/2.png')" alt />
					</div>
				</div>
				<div class="column is-5-desktop is-12-touch">
					<b-tabs id="Auth-tab" position="is-centered" type="is-boxed" expanded v-model="activeTab">
						<b-tab-item
							:visible="!isRecoverPassword && !isActivation"
							class="st-tab-item"
							id="new_account"
							value="new_account"
							:label="t.new_account"
						>
							<Register />
						</b-tab-item>

						<b-tab-item
							:visible="!isRecoverPassword && !isActivation"
							class="st-tab-item"
							id="login"
							value="login"
							:label="t.same_account"
						>
							<Login :showSocial="true" />
						</b-tab-item>
						<b-tab-item
							:visible="isRecoverPassword"
							class="st-tab-item"
							id="recover_password"
							value="recoverPassword"
							:label="t.recover_password"
						>
							<RecoverPassword />
						</b-tab-item>
						<b-tab-item
							:visible="isActivation"
							class="st-tab-item"
							id="recover_password"
							value="activation"
							:label="t.activation"
						>
							<Activation />
						</b-tab-item>
					</b-tabs>
				</div>
			</div>
		</section>
		<section class="has-background-blue is-hidden-desktop">
			<div class="columns is-mobile is-multiline">
				<div class="column is-offset-1 is-11">
					<img @click="$router.push({name:'LandingPage'})" :src="require('@/assets/icons/logo.svg')" alt />
				</div>
				<div class="column is-offset-1 is-6">
					<h4 class="is-title-1-m has-text-white">{{t.welcome}}</h4>
				</div>
				<div class="column is-12">
					<img :src="require('@/assets/imgs/Auth/1.png')" alt />
				</div>
			</div>
			<div class="columns is-mobile is-multiline has-background-white">
				<div v-if="activeTab == 'new_account' && !isRecoverPassword && !isActivation" class="column is-offset-1 is-10">
					<h4 class="has-text-black is-headline-m">{{t.register.register_mobile}}</h4>
					<Register />
					<b-button
						expanded
						class="is-headline-m-m has-text-black button-account"
						type="is-ghost"
						@click="activeTab='login'"
					>{{t.register.have_account}}</b-button>
				</div>
				<div v-else-if="activeTab == 'login' && !isRecoverPassword && !isActivation" class="column is-offset-1 is-10">
					<h4 class="has-text-black is-headline-m">{{t.login.login_mobile}}</h4>
					<Login :showSocial="true" />
					<b-button
						expanded
						class="is-headline-m-m has-text-black button-account"
						type="is-ghost"
						@click="activeTab='new_account'"
					>{{t.login.register_account}}</b-button>
				</div>
				<div v-else-if="activeTab=='recoverPassword' && !isActivation" class="column is-offset-1 is-10">
					<h4 class="has-text-black is-headline-m">{{t.recover_password}}</h4>
					<RecoverPassword :showSocial="true" />
				</div>
				<div v-else class="column is-offset-1 is-10">
					<h4 class="has-text-black is-headline-m">{{t.recover_password}}</h4>
					<Activation :showSocial="true" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	// @ is an alias to /src
	import ClientNavbar from "@/components/LandingPage/ClientNavbar";
	import Login from "@/components/Auth/Login";
	import Register from "@/components/Auth/Register";
	import RecoverPassword from "@/components/Auth/RecoverPassword";
	import Activation from "@/components/Auth/Activation";

	export default {
		name: "Auth",
		components: {
			ClientNavbar,
			Register,
			Login,
			RecoverPassword,
			Activation
		},
		data() {
			return {
				t: this.$t("views.Auth"),
				navClass: "is-blue",
				section: null,
				activeTab: "login",
			};
		},
		watch: {
			isRecoverPassword(_new) {
				if (_new == false) this.activeTab = "login";
			},
			isActivation(_new) {
				if (_new == false) this.activeTab = "login";
			},
		},
		created() {
			if (this.isRecoverPassword) this.activeTab = "recoverPassword";
			if (this.isActivation) this.activeTab = "activation";
		},
		computed: {
			paddingTop() {
				return {
					"padding-top": this.isMobile
						? 0
						: `${this.$store.getters["css/getNavbarHeight"]}px`,
				};
			},
			isRecoverPassword() {
				return this.$route.name == "RecoverPassword";
			},
			isActivation() {
				return this.$route.name == "Activation";
			},
		},
		methods: {},
	};
</script>

<style lang="scss" scoped>
		@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';
	section {
		min-height: 100vh;
	}
	section.is-hidden-touch {
		overflow: hidden;
		img {
			margin-top: 2 * $space;
		}

		.st-tab-item {
			margin-top: 2 * $space;
			outline: unset;
		}

		#new_account > div.columns.is-mobile.is-multiline {
			> div:nth-child(4) {
				margin-top: $space;
			}
			> div:nth-child(3) {
				margin-top: $space;
			}
		}
	}

	section.is-hidden-desktop {
		padding-top: 1.5 * $space;
		position: relative;
		overflow-y: scroll;
		overflow-x: hidden;

		> .columns:nth-of-type(1) {
			position: relative;
			padding-bottom: 5vh;

			> .column:nth-of-type(2) {
				z-index: 2;
			}
			> .column:nth-of-type(3) {
				position: absolute;
				left: 59vw;
				@include until($mobile-s) {
					top: -9vh;
				}
				@include until($mobile-m) {
					top: -8vh;
				}
				@include from($mobile-m) {
					top: -7vh;
				}

				padding: 0;
				z-index: 1;
				img {
					height: 36vh;
				}
			}
		}
		> .columns:nth-of-type(2) {
			z-index: 2;
			position: absolute;
			padding-bottom: 5vh;
			left: 0.75rem;
			width: 100%;
			h4 {
				margin-top: $space;
				margin-bottom: $space;
			}
		}

		.button-account {
			margin-top: $space/2;
		}
		.button-account:focus {
			box-shadow: unset;
		}
	}
</style>