<template>
	<div class="first-div">
		<div class="columns is-mobile is-multiline">
			<div class="column is-offset-1-desktop is-10-desktop is-offset-2-fullhd is-8-fullhd is-12-touch">
				<b-button
					icon-pack="st"
					icon-left="google"
					icon-size="is-medium"
					class="has-text-black has-text-left is-body-d-d is-subhead-m-m button-social"
					expanded
				>{{t.register.google}}</b-button>
				<b-button
					icon-pack="st"
					icon-left="facebook"
					class="has-text-black has-text-left is-body-d-d is-subhead-m-m button-social"
					expanded
				>{{t.register.facebook}}</b-button>
			</div>
		</div>
		<div class="separator is-body-d has-text-gray">
			<span>{{$t("shared.or")}}</span>
		</div>
		<form @submit.prevent="formSubmit" class="columns is-mobile is-multiline">
			<div class="column is-6-touch is-4-desktop is-offset-2-desktop">
				<b-input
					class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
					outlined
					required
					autocomplete="on"
					name="given-name"
					v-model="form.name"
					:placeholder="$t('fields.name')"
				></b-input>
			</div>
			<div class="column is-6-touch is-4-desktop">
				<b-input
					class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
					outlined
					required
					autocomplete="on"
					name="family-name"
					v-model="form.surname"
					:placeholder="$t('fields.surname')"
				></b-input>
			</div>
			<div class="column is-12-touch is-offset-2-desktop is-8-desktop">
				<b-input
					class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
					outlined
					icon-pack="st"
					icon="envelope"
					autocomplete="on"
					name="email"
					type="email"
					v-model="form.email"
					required
					:placeholder="$t('fields.email')"
				></b-input>
			</div>
			<div class="column is-12-touch is-offset-2-desktop is-8-desktop">
				<b-button
					expanded
					class="is-headline-bold-d-d is-headline-m-m"
					type="is-black"
					native-type="submit"
				>{{t.register.register}}</b-button>
			</div>
			<div class="column is-offset-3-desktop is-6-desktop is-hidden-touch">
				<h4 class="has-text-black is-caption-d has-text-centered">{{t.register.conf}}</h4>
			</div>
		</form>
	</div>
</template>

<script>
	import { createUser } from '@/api/users';
	
	export default {
		name: "Register",
		data() {
			return {
				t: this.$t("views.Auth"),
				form: {
					email: "",
					name: "",
					surname: "",
				},
			};
		},
		methods: {
			formSubmit() {
				createUser(this.form)
				.then(response => {
					this.form = {
						email: "",
						name: "",
						surname: "",
					};
					this.$buefy.toast.open({
						message: this.$t('components.Auth.Register.api.createUser.success'),
						position: "is-top",
						type: "is-primary"
					});
				})
				.catch(error => {
					let message = this.$t('components.Auth.Register.api.createUser.error');
					if(error.response.data.code == "DuplicatedUser") message = this.$t('components.Auth.Register.api.createUser.duplicated');
					this.$buefy.toast.open({
						message: message,
						position: "is-top",
						type: "is-danger"
					});
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
		@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';
	.button-social:nth-of-type(1) {
		margin-bottom: $space/2;
	}

	.first-div > .columns:nth-of-type(1) {
		@include until($touch) {
			margin-bottom: unset;
		}
	}
</style>