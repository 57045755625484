<template>
	<div class="first-div">
		<form @submit.prevent="formSubmit" class="columns is-mobile is-multiline">
			<div class="column is-offset-2-desktop is-8-desktop is-12-touch">
				<b-input
					class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
					outlined
					icon-pack="st"
					autocomplete="new-password"
					name="new-password"
					icon="password"
					type="password"
					minlength="8"
					v-model="form.password"
					required
					password-reveal
					:placeholder="$t('fields.new_password')"
				></b-input>
			</div>
			<div class="column is-offset-2-desktop is-8-desktop is-12-touch">
				<b-button
					expanded
					:loading="isLoading"
					class="is-headline-bold-d-d is-headline-m-m"
					type="is-black"
					native-type="submit"
				>{{t.update}}</b-button>
			</div>
		</form>
	</div>
</template>

<script>
	import { activateUser } from "@/api/users";
	export default {
		name: "Activation",
		data() {
			return {
				t: this.$t("components.Auth.Activation"),
				form: {
					password: null,
				},
				isLoading: false,
			};
		},
		created() {},
		methods: {
			formSubmit() {
				this.isLoading = true;
				activateUser({
					code: this.$route.query.code
					},
					this.form)
					.then((response) => {
						this.$buefy.toast.open({
							message: this.t.api.success,
							position: "is-bottom",
							type: "is-success",
						});
						this.$router.push({ name: "Auth" });
					})
					.catch((error) => {
						this.$buefy.toast.open({
							message: this.t.api.error,
							position: "is-bottom",
							type: "is-error",
						});
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/variables/all";
    @import "~bulma/sass/utilities/mixins.sass";
	.first-div > .columns:nth-of-type(1) {
		@include until($touch) {
			margin-bottom: unset;
		}
	}
</style>