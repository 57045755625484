<template>
	<div>
		<ClientNavbar :navClass="navClass" :section="section" />
		<section :style="paddingTop" id="home" ref="home">
			<div class="columns is-mobile is-multiline">
				<div class="column is-10-touch has-text-left is-offset-1 is-offset-2-fullhd is-4-fullhd">
					<div class="columns is-mobile">
						<div class="column is-9-touch">
							<h1 class="has-text-white has-text-left is-big-title-d-d is-title-1-m-m home_text">{{t.title1}}</h1>
							<p class="has-text-white has-text-left is-headline-d-d is-body-m-m home_text">{{t.subtitle1}}</p>
							<img class="is-hidden-desktop" src="@/assets/imgs/LandingPage/1.png" />
						</div>
					</div>
					<div class="columns is-mobile">
						<div class="column is-12">
							<b-button
								class="is-headline-bold-d-d is-headline-m-m is-full-width-mobile"
								type="is-black"
								@click="$router.push({name: 'Auth'})"
							>{{t.btn1}}</b-button>
						</div>
					</div>
				</div>
				<div class="column is-6-fullhd is-10-touch is-offset-1-touch is-hidden-touch">
					<img src="@/assets/imgs/LandingPage/1.png" />
				</div>
			</div>
		</section>
		<section :style="paddingTop" id="insurance" ref="insurance">
			<div class="columns is-mobile is-multiline">
				<div class="column has-text-left is-offset-1 is-offset-2-fullhd is-6-desktop is-9-touch">
					<h1 class="has-text-white is-title-2-d-d is-title-1-m-m">{{t.title2}}</h1>
				</div>
			</div>
			<div class="columns is-mobile is-multiline">
				<div class="column has-text-left is-offset-1 is-offset-2-fullhd is-5-desktop is-9-touch">
					<p class="has-text-white is-headline-d-d is-body-m-m">{{t.subtitle2}}</p>
				</div>
			</div>
			<div class="columns is-mobile is-multiline">
				<div class="column is-offset-1 is-offset-2-fullhd is-10 is-8-fullhd">
					<div class="columns is-mobile is-multiline">
						<div class="column is-4-desktop is-6-touch">
							<TypeCard text="car" :img="require('@/assets/imgs/LandingPage/car.png')" />
						</div>
						<div class="column is-4-desktop is-6-touch">
							<TypeCard text="health" :img="require('@/assets/imgs/LandingPage/health.png')" />
						</div>
						<div class="column is-4-desktop is-6-touch">
							<TypeCard text="house" :img="require('@/assets/imgs/LandingPage/house.png')" />
						</div>
						<div class="column is-4-desktop is-6-touch">
							<TypeCard text="life" :img="require('@/assets/imgs/LandingPage/life.png')" />
						</div>
						<div class="column is-4-desktop is-6-touch">
							<TypeCard text="kid" :img="require('@/assets/imgs/LandingPage/kid.png')" />
						</div>
						<div class="column is-4-desktop is-6-touch">
							<TypeCard text="teeth" :img="require('@/assets/imgs/LandingPage/teeth.png')" />
						</div>
					</div>
				</div>
			</div>
		</section>
		<section :style="paddingTop" id="how" ref="how">
			<div class="columns is-mobile is-multiline">
				<div class="column is-9-mobile is-offset-1 is-offset-2-fullhd is-5-desktop is-3-fullhd">
					<h1 class="has-text-black has-text-left is-title-2-d-d is-title-1-m-m">{{t.title3}}</h1>
					<p class="has-text-black is-headline-d-d is-body-m-m has-text-left">{{t.text3}}</p>
				</div>
				<div class="column is-offset-3-touch is-9-touch has-text-right">
					<img src="@/assets/imgs/LandingPage/how.png" alt />
				</div>
			</div>
			<div class="columns mt-5 is-mobile is-multiline">
				<div
					class="column has-background-blue is-offset-1-desktop is-offset-2-fullhd is-11-desktop is-10-fullhd is-12-touch"
				>
					<div class="columns is-mobile has-text-left has-text-white">
						<div class="column is-3-desktop is-8-touch">
							<h4 class="is-headline-d">01</h4>
							<br />
							<h3 class="is-title-2-m">{{t.how.title1}}</h3>
							<p class="is-body-d-d is-body-m-m">{{t.how.text1}}</p>
						</div>
						<div class="column is-3-desktop is-8-touch">
							<h4 class="is-headline-d">02</h4>
							<br />
							<h3 class="is-title-2-m">{{t.how.title2}}</h3>
							<p class="is-body-d-d is-body-m-m">{{t.how.text2}}</p>
						</div>
						<div class="column is-3-desktop is-8-touch">
							<h4 class="is-headline-d">03</h4>
							<br />
							<h3 class="is-title-2-m">{{t.how.title3}}</h3>
							<p class="is-body-d-d is-body-m-m">{{t.how.text3}}</p>
						</div>
						<div class="column is-3-desktop is-8-touch">
							<h4 class="is-headline-d">04</h4>
							<br />
							<h3 class="is-title-2-m">{{t.how.title4}}</h3>
							<p class="is-body-d-d is-body-m-m">{{t.how.text4}}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section :style="paddingTop" id="about" ref="about">
			<div class="columns is-mobile is-multiline">
				<div class="column is-offset-1 is-4-desktop is-10-touch is-offset-2-fullhd is-3-fullhd">
					<h1 class="has-text-white has-text-left is-title-2-d-d is-title-1-m-m">{{t.about.title1}}</h1>
					<p class="has-text-white has-text-left is-body-d-d is-body-m-m">{{t.about.text1}}</p>
				</div>
				<div class="column is-offset-1 is-5-desktop is-4-fullhd is-offset-1-mobile is-offset-1-fullhd is-10-touch">
					<h1
						class="has-text-white is-hidden-touch has-text-left is-body-d-d is-body-m-m"
					>{{t.about.text2}}</h1>
					<div class="columns is-mobile is-multiline">
						<div class="column is-4-desktop is-6-touch">
							<h1 class="has-text-white has-text-left is-big-title-d-d is-big-title-m-m">{{t.about.value1}}</h1>
							<h2 class="has-text-white has-text-left is-body-d-d is-body-m-m">{{t.about.text3}}</h2>
						</div>
						<div class="column is-4-desktop is-6-touch is-offset-1-desktop">
							<h1 class="has-text-white has-text-left is-big-title-d-d is-big-title-m-m">{{t.about.value2}}</h1>
							<h2 class="has-text-white has-text-left is-body-d-d is-body-m-m">{{t.about.text4}}</h2>
						</div>
					</div>
				</div>
			</div>
			<footer class="footer has-background-black has-text-white">
				<div class="columns is-mobile">
					<div class="column is-4-touch is-offset-1-desktop">
						<div class="tabs has-text-white is-body-m-d is-subhead-m-m is-left">
							<ul class="has-text-left">
								<li>
									<a @click="scrollTo('LandingPage', 'insurance')">{{t.insurance}}</a>
								</li>
								<li>
									<a @click="scrollTo('LandingPage', 'how')">{{t.process}}</a>
								</li>
								<li>
									<a @click="scrollTo('LandingPage', 'about')">{{t.aboutUs}}</a>
								</li>
								<li>
									<a>{{t.contacts}}</a>
								</li>
								<li>
									<a>{{t.carrer}}</a>
								</li>
								<li>
									<a>{{t.legal}}</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="column is-offset-1-desktop is-offset-1-tablet">
						<h3 class="is-body-m-d is-caption-m-m has-text-white">{{t.subscribe}}</h3>
						<div class="subscrive-input">
							<b-input
								class="clear-input is-caption-m-m is-body-m-d has-text-white"
								outlined
								:placeholder="t.email"
							></b-input>
							<b-button class="clear-button has-text-white">
								<b-icon pack="st" icon="arrow-right" size="is-small"></b-icon>
							</b-button>
						</div>
					</div>
				</div>
				<div class="columns is-mobile">
					<div class="column is-offset-1-desktop">
						<h2
							class="is-body-m-d is-caption-m-m has-text-gray"
						>{{$t("views.LandingPage.rights", [new Date().getFullYear()])}}</h2>
					</div>
				</div>
			</footer>
		</section>
		<UserPopup />
	</div>
</template>

<script>
	// @ is an alias to /src
	import ClientNavbar from "@/components/LandingPage/ClientNavbar";

	import TypeCard from "@/components/LandingPage/TypeCard";

	import UserPopup from "@/components/LandingPage/UserPopup";

	export default {
		name: "LandingPage",
		components: {
			ClientNavbar,
			TypeCard,
			UserPopup
		},
		data() {
			return {
				t: this.$t("views.LandingPage"),
				navClass: "is-blue",
				section: "home",
			};
		},
		created() {
			window.addEventListener("scroll", this.handleScroll);
		},
		destroyed() {
			window.removeEventListener("scroll", this.handleScroll);
		},
		computed: {
			paddingTop() {
				return {
					"padding-top": `${this.$store.getters["css/getNavbarHeight"]}px`,
				};
			},
		},
		methods: {
			handleScroll() {
				if (this.isElementInViewport(this.$refs.home)) {
					this.navClass = "is-blue";
					this.section = "home";
				} else if (this.isElementInViewport(this.$refs.insurance)) {
					this.navClass = "is-black";
					this.section = "insurance";
				} else if (this.isElementInViewport(this.$refs.how)) {
					this.navClass = "is-white";
					this.section = "how";
				} else if (this.isElementInViewport(this.$refs.about)) {
					this.navClass = "is-blue";
					this.section = "about";
				}
			},
			isElementInViewport(el) {
				var rect = el.getBoundingClientRect();
				if (
					rect.top >
					-(rect.height - this.$store.getters["css/getNavbarHeight"])
				)
					return true;
				return false;
			},
		},
	};
</script>

<style lang="scss" scoped>
		@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';
	section {
		min-height: 100vh;
	}

	.is-offset-64 {
		margin-left: 2 * $space;
	}
	.home_text{
		@include until($mobile){
			width: 80%;
		}
	}
	#home {
		background-color: $blue;

		p {
			margin-top: $space;
		}
		.button {
			width: 14em;
			height: 2.7em;
			margin-top: 2 * $space;
			@include until($mobile) {
				margin-top: 4rem !important;
			}
			@include until($desktop) {
				margin-top: 0px;
			}
		}

		@include until($touch) {
			> .columns {
				position: relative;
				> .column:nth-of-type(1) {
					> .columns:nth-of-type(1) {
						height: calc(100vh - 194px - 163px);
						position: relative;
						> .column:nth-of-type(1) {
							position: absolute;
							top: 25%;
							transform: translate(0, -25%);
							> img {
								position: absolute;
								top: 50%;
								left: 67%;
								z-index: -1;
								transform: translate(0, -50%);
							}
						}
					}
				}
			}
		}

		@include until($desktop) {
			> .columns {
				position: relative;
				> .column:nth-of-type(1) {
					> .columns:nth-of-type(1) {
						height: calc(100vh - 194px - 163px);
						position: relative;
						> .column:nth-of-type(1) {
							position: absolute;
							top: 40%;
							/* transform: translate(0, -40%); */
							> img {
								position: absolute;
								top: 50%;
								left: 78%;
								z-index: -1;
								transform: translate(0, -50%);
								max-width: 75%;
							}
						}
					}
				}
			}
		}
	}

	#insurance {
		background-color: $black;
		padding-bottom: 2 * $space;
		.columns:nth-of-type(3) {
			margin-top: 10vh;
		}
	}

	#how {
		min-height: 150vh;
		background-color: $white;
		position: relative;
		h1 {
			padding-top: 2 * $space;
		}
		p {
			margin-top: $space;
		}
		> .columns:nth-of-type(1) {
			@include until($desktop) {
				padding-bottom: calc(342px - 128px);
			}
		}
		> .columns:nth-of-type(2) {
			position: absolute;
			top: 50%;
			@include until($desktop) {
				top: unset;
				bottom: 10vh;
				width: 100vw;
				.columns {
					overflow-x: scroll;
				}
			}

			> .column {
				padding: 2 * $space;
			}
			.is-3 {
				width: 21%;
			}
		}
	}

	#about {
		background-color: $blue;
		position: relative;

		> .columns:nth-of-type(1) {
			@include until($desktop) {
				padding-bottom: calc(336px + (2 * #{$space}));
			}
			min-height: 100vh;
			margin-bottom: unset;
		}
		.columns {
			> .column:nth-of-type(1) {
				p {
					margin-top: $space;
				}
			}
			.columns {
				margin-top: 16px;
				h2 {
					margin-top: 16px;
				}
			}
		}

		footer {
			position: absolute;
			width: 100%;
			bottom: 0;
			min-height: 265px;
			padding-bottom: 2rem;
			.subscrive-input {
				display: inline-flex;
				margin-top: 20px;
				border-bottom: 1px solid $white;

				.clear-input {
					color: $white;
				}
			}
			h3 {
				padding: 8px 0px;
			}
			.columns:nth-of-type(1) {
				margin-bottom: 2 * $space;
			}
			.columns:nth-of-type(2) {
				position: absolute;
				bottom: $space;
				width: 98%;
			}
		}

		.tabs {
			ul {
				border: unset;
				@include until($desktop) {
					display: block;
					align-items: left;
				}
			}
			a {
				color: inherit;
				border: inherit;
				@include until($desktop) {
					text-align: left;
					justify-content: left;
				}
			}
			li:nth-of-type(1) {
				@include from($desktop) {
					a {
						padding-left: 0;
					}
				}
			}
		}
	}
</style>